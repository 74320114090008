<template>
  <div class="page">
    <h1><w-icon>mdi mdi-text-box-check-outline</w-icon>特許</h1>
    <div class="content">
      <h2>取り組み</h2>
      <p>革新的な仕組みと その網羅的な技術に対して、丁寧に作成した特許を出願しています</p>
      <h2>現在のステータス</h2>
      <p>未契約</p>
      <h2>提案</h2>
      <ul>
        <li>御社だけの専売特許として、本技術を活用しませんか？</li>
        <li>製造が容易で原価も安い本品で、御社だけのオリジナル新商品を販売しませんか？</li>
      </ul>
      <h2>希望契約</h2>
      <ul>
        <li>専用実施権として企業様の差別化の一助になれば幸いです</li>
        <li>出願審査請求を資金面で援助して頂ける形態の契約を希望いたします</li>
      </ul>
      <h2>募集</h2>
      <ul>
        <li>ライセンス契約時の契約金の範囲内で報酬をお支払いする形式で、契約の可能性のある企業様を幅広く探して頂ける方を募集しております</li>
      </ul>
      <img src="/image/my/mail.png" alt="メールアドレス">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.page {
  img {
    margin-top: 70px;
  }
}
</style>
